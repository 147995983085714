import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { FCM } from '@ionic-native/fcm/ngx'
import { HttpClientModule } from '@angular/common/http';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { Network } from '@ionic-native/network/ngx';
import { Device } from '@ionic-native/device/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Stripe } from '@ionic-native/stripe/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Market } from '@ionic-native/market/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AgmCoreModule } from '@agm/core';
import { StarRatingModule } from 'ionic4-star-rating';
import { CallNumber } from '@ionic-native/call-number/ngx';

import {
  NgxUiLoaderModule,
  NgxUiLoaderHttpModule,
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  PB_DIRECTION,
} from "ngx-ui-loader";

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsColor: '#009688',
  textColor: 'yellow',
  // logoUrl: "assets/icon/favicon.png",
  logoPosition: "center-center",
  logoSize: 70,
  fgsPosition: POSITION.centerCenter,
  fgsSize: 50,
  fgsType: SPINNER.threeBounce,
  pbThickness: 2
};

const base_path = environment.baseURL;

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    StarRatingModule,
    IonicModule.forRoot(), 
    AppRoutingModule, 
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({exclude: [base_path+"marketplace/is-product-same-store",base_path+"marketplace/cart",
    base_path+"marketplace/cart-item-quantity",base_path+"marketplace/get-cart-id",base_path+"marketplace/remove-cartitem",base_path+"marketplace/all-zip-codes"], showForeground: true }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA1kyOBR994i8FtlbgzKHub-hWWS-D49bw', 
      libraries: ['places']
    })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    FCM,
    File,
    FileTransfer,
    Device,
    Geolocation,
    NativeGeocoder,
    Network,
    LocationAccuracy,
    AndroidPermissions,
    Stripe,
    AppVersion,
    CallNumber,
    Market
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
