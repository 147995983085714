import { Injectable } from '@angular/core';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Platform,AlertController } from '@ionic/angular';
import { Market } from '@ionic-native/market/ngx';
import { ApiService } from '../api/api.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  constructor(public api: ApiService,private platform: Platform,private appVersion: AppVersion,public alertController: AlertController,private market: Market) { }

   async checkForUpdates(){
    const versionNumber = await this.appVersion.getVersionNumber();
    const versionCode = await this.appVersion.getVersionCode();
    console.log(versionNumber,versionCode)

    this.api.getAppVersionDetails().subscribe((result:any) => {
      console.log(result)
      if(result.status == true){
          //Version Check
          const splittedVersion = versionNumber.split('.')

          if(this.platform.is('android')){
            if(result.data.android_version_number != '' && result.data.android_version_code != ''){
                let serverVersion:any = result.data.android_version_number.split('.')
                var serverVersionCode:any = JSON.parse(result.data.android_version_code)
    
                console.log(serverVersion,serverVersionCode)
    
                if(serverVersion[0] > splittedVersion[0]){
                  this.UpdateAppConfirmation("Important App Update","Please update your app to the latest version to continue using it.")
                }
                else if(serverVersion[1] > splittedVersion[1]){
                  this.UpdateAppConfirmation("App update available","There's a new version available, would you like to get it now?")
                }
                else if(result.data.android_version_number == versionNumber){
                  if(serverVersionCode > versionCode){
                    this.UpdateAppConfirmation("App update available","There's a new version available, would you like to get it now?")
                  }
                }
            }
          }
          // else if(this.platform.is('ios')){
          //   var serverVersion:any = result.data.ios_version_number.split('.')
          //   var serverVersionCode:any = result.data.ios_version_code

          //   if(serverVersion[0] > splittedVersion[0]){
          //     this.UpdateAppConfirmation("Important App Update","Please update your app to the latest version to continue using it.")
          //   }
          //   else if(serverVersion[1] > splittedVersion[1]){
          //     this.UpdateAppConfirmation("App update available","There's a new version available, would you like to get it now?")
          //   }
          // }
        }
      })
   }

   openAppstoreEntry(){
    if(this.platform.is('android')){
      this.market.open(environment.bundleId);
    }
    // else {
    //   this.market.open('id1573097271');
    // }
   }

   async UpdateAppConfirmation(header:any,message:any) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: header,
      message: message,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Download',
          handler: () => {
            this.openAppstoreEntry()
          }
        }
      ]
    });

    await alert.present();
  }


  async UpdateAppAlert(header:any,message:any) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: header,
      message: message,
      buttons: [
        {
          text: 'Download',
          handler: () => {
            this.openAppstoreEntry()
          }
        }
      ]
    });

    await alert.present();
  }
}
