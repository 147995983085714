import { Injectable,Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Device } from '@ionic-native/device/ngx';
import { environment } from '../../../environments/environment';
import {BehaviorSubject} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  base_path = environment.baseURL;

  public myLatitude = new BehaviorSubject<any>('');
  public myLongitde = new BehaviorSubject<any>('');
  private myAddress = new BehaviorSubject<any>('');
  private locationErr = new BehaviorSubject<any>(false);

  constructor(private http: HttpClient,private device: Device,private router: Router,) { 

  }
    getlatitude() {
      return this.myLatitude.asObservable();
    }
  
    setlatitude(val: any) {
      this.myLatitude.next(val);
    }

    getlongitude() {
      return this.myLongitde.asObservable();
    }
  
    setlongitude(val: any) {
      this.myLongitde.next(val);
    }

    getaddress() {
      return this.myAddress.asObservable();
    }
  
    setaddress(val: any) {
      this.myAddress.next(val);
    }

    getLocationErr() {
      return this.locationErr.asObservable();
    }
  
    setLocaionErr(val: any) {
      this.locationErr.next(val);
    }

  device_token:any = this.device.uuid 

    // Http Options
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    getHeaderDetails(){
      if(localStorage.getItem('token') == undefined || localStorage.getItem('token') == null || localStorage.getItem('token') == 'null'){
        let httpOptions:any = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          })
        }
        return httpOptions
      }
      else{
        let token:any = localStorage.getItem('token')
        let httpOptions:any = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + token
          })
        }
        return httpOptions
      }
    }
  
    // Handle API errors
    handleError(error: HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
          if(error.status === 401 || error.status === 403){
            localStorage.removeItem('token');
            window.location.href = '/login';
            // window.location.reload()
            // this.router.navigate(['/login'])
          }
      }
      // return an observable with a user-facing error message
      return throwError(
        'Something bad happened; please try again later.');
    };


  // Get banner images
  getBannerList(): Observable<any> {
    let httpOptions:any = this.getHeaderDetails()
    return this.http
      .get<any>(this.base_path+"marketplace/banners?store_id="+localStorage.getItem('store_id'),httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  // Get Veg box list
  getVegBoxList(): Observable<any> {
    let httpOptions:any = this.getHeaderDetails()
    return this.http
      .get<any>(this.base_path+"marketplace/vegbox-items", httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  // Get Category Name list
  getCategoryNameList(): Observable<any> {
    let httpOptions:any = this.getHeaderDetails()
    return this.http
      .get<any>(this.base_path+"marketplace/store-category?store_id="+localStorage.getItem('store_id'), httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  // Get All Product list 
  getAllProductsList(): Observable<any> {
    let httpOptions:any = this.getHeaderDetails()
    return this.http
      .get<any>(this.base_path+"marketplace/store-products?store_id="+localStorage.getItem('store_id'), httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  // Get Product list with pagination
  getProductsListWithPagination(url:any): Observable<any> {
    let httpOptions:any = this.getHeaderDetails()
    return this.http
      .get<any>(url, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  // Get Specific Category Product list 
  getSpecificCatgProductList(id:any): Observable<any> {
    let httpOptions:any = this.getHeaderDetails()
    return this.http
      .get<any>(this.base_path+"marketplace/store-products?category_id="+id+"&store_id="+localStorage.getItem('store_id'), httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  // Adding Veg Box To Cart
  addVegBoxTocart(item:any): Observable<any> {
    let httpOptions:any = this.getHeaderDetails()
    return this.http
      .post<any>(this.base_path+"marketplace/add-box-items", item, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  // Adding Product To Cart
  addProductTocart(item:any): Observable<any> {
    let httpOptions:any = this.getHeaderDetails()
    return this.http
      .post<any>(this.base_path+"marketplace/cart", item, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  // Getting Cart items
  getCartItemList(): Observable<any> {
    let httpOptions:any = this.getHeaderDetails()
    return this.http
      .get<any>(this.base_path+"marketplace/cart/"+this.device.uuid, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  // Removing a Product From Cart
  removeProductFromCart(item:any): Observable<any> {
    let httpOptions:any = this.getHeaderDetails()
    return this.http
      .post<any>(this.base_path+"marketplace/remove-cartitem", item, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  // Removing all Product From Cart
  removeAllProductFromCart(item:any): Observable<any> {
    let httpOptions:any = this.getHeaderDetails()
    return this.http
      .post<any>(this.base_path+"marketplace/remove-all-cartitem", item, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  // Get Count of items in cart 
  getCartCount(): Observable<any> {
    let httpOptions:any = this.getHeaderDetails()
    return this.http
      .get<any>(this.base_path+"marketplace/cart-item-quantity", httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  // Get saved address list
  getAddressList(): Observable<any> {
    let httpOptions:any = this.getHeaderDetails()
    return this.http
      .get<any>(this.base_path+"marketplace/delivery-address/"+this.device.uuid, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  // Delete address by id
  deleteAddress(id:any) {
    let httpOptions:any = this.getHeaderDetails()
    return this.http
      .delete<any>(this.base_path + "marketplace/delivery-address/" + id, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  // Update address by id
  updateAddress(id:any, item:any): Observable<any> {
    let httpOptions:any = this.getHeaderDetails()
    return this.http
      .put<any>(this.base_path + "marketplace/delivery-address/" + id, item, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  // Add delivery address 
  addDeliveryAddress(item:any): Observable<any> {
    let httpOptions:any = this.getHeaderDetails()
    return this.http
      .post<any>(this.base_path+"marketplace/delivery-address", item, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  // Place Order
  placeOrder(item:any): Observable<any> {
    let httpOptions:any = this.getHeaderDetails()
    return this.http
      .post<any>(this.base_path+"marketplace/place-order", item, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  // Get Order and Cart id
  getCartId(item:any): Observable<any> {
    let httpOptions:any = this.getHeaderDetails()
    return this.http
      .post<any>(this.base_path+"marketplace/get-cart-id", item, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  // Get place order history
  getPlaceOrderHistory(): Observable<any> {
    let httpOptions:any = this.getHeaderDetails()
    return this.http
      .get<any>(this.base_path+"marketplace/placed-order-history?device_token="+this.device.uuid, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  // Get progress order history
  getProgressOrderHistory(): Observable<any> {
    let httpOptions:any = this.getHeaderDetails()
    return this.http
      .get<any>(this.base_path+"marketplace/progress-order-history?device_token="+this.device.uuid, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  // fcm token
  registerFCMToken(item:any): Observable<any> {
    let httpOptions:any = this.getHeaderDetails()
    return this.http
      .post<any>(this.base_path+"marketplace/fcm-token", item, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  // Get About page content
  getAboutContent(): Observable<any> {
    let httpOptions:any = this.getHeaderDetails()
    return this.http
      .get<any>(this.base_path+"marketplace/about-content", httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }


  // setDownloadPath(value:any){
  //   localStorage.setItem("DownloadPath",value);
  // }

  // getDownloadPath(){
  //   return localStorage.getItem("DownloadPath") || "";
  // }

  // Generate otp api
  generateOTP(value:any): Observable<any> {
    let httpOptions:any = this.getHeaderDetails()
    return this.http
      .post<any>(this.base_path+"user/generate-otp",value, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  // Verify otp api
  verifyOTP(value:any): Observable<any> {
    let httpOptions:any = this.getHeaderDetails()
    return this.http
      .post<any>(this.base_path+"user/verifyotp",value, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

    // payment
    makePayment(value:any): Observable<any> {
      let httpOptions:any = this.getHeaderDetails()
      return this.http
        .post<any>(this.base_path+"marketplace/make-payment",value, httpOptions)
        .pipe(
          retry(2),
          catchError(this.handleError)
        )
    }

  // Get zip codes
    getZipCodes(): Observable<any> {
      let httpOptions:any = this.getHeaderDetails()
      return this.http
        .get<any>(this.base_path+"marketplace/all-zip-codes?subscriber_id="+localStorage.getItem('subscriber_id'),httpOptions)
        .pipe(
          retry(2),
          catchError(this.handleError)
        )
    }

    // Get search data from all products
    getSearchData(value:any): Observable<any> {
      let httpOptions:any = this.getHeaderDetails()
      let url:any = ''
      if(value.id == 1) {
        url = "marketplace/all-products?q="+value.search_value
      }
      else {
        url = "marketplace/all-products?category_id="+value.id+"&q="+value.search_value
      }
      return this.http
        .get<any>(this.base_path+url,httpOptions)
        .pipe(
          retry(2),
          catchError(this.handleError)
        )
    }

    // Get all payment types
    getPaymentTypes(): Observable<any> {
      let httpOptions:any = this.getHeaderDetails()
      return this.http
        .get<any>(this.base_path+"marketplace/payment-type-master?subscriber_id="+localStorage.getItem('cart-subscriber-id'),httpOptions)
        .pipe(
          retry(2),
          catchError(this.handleError)
        )
    }

    getPrivacyPolicy(): Observable<any> {
      let httpOptions:any = this.getHeaderDetails()
      return this.http
        .get<any>(this.base_path+"marketplace/privacy-policy",httpOptions)
        .pipe(
          retry(2),
          catchError(this.handleError)
        )
    }

    getTermsAndConditions(): Observable<any> {
      let httpOptions:any = this.getHeaderDetails()
      return this.http
        .get<any>(this.base_path+"marketplace/terms-and-condition",httpOptions)
        .pipe(
          retry(2),
          catchError(this.handleError)
        )
    }


    // minimum order validation
    minOrderCheck(value:any): Observable<any> {
      let httpOptions:any = this.getHeaderDetails()
      return this.http
        .post<any>(this.base_path+"marketplace/minimum-order-amount-check",value, httpOptions)
        .pipe(
          retry(2),
          catchError(this.handleError)
        )
    }

    getAppVersionDetails(): Observable<any> {
      let httpOptions:any = this.getHeaderDetails()
      return this.http
        .get<any>(this.base_path+"marketplace/app-config-details",httpOptions)
        .pipe(
          retry(2),
          catchError(this.handleError)
        )
    }

    getStoreListByLatLng(value: any){
      let httpOptions:any = this.getHeaderDetails()
      return this.http
        .post<any>(this.base_path+"marketplace/stores",value, httpOptions)
        .pipe(
          retry(2),
          catchError(this.handleError)
        )
    }

    isProductAlreadyInCart(){
      let httpOptions:any = this.getHeaderDetails()
      return this.http
        .post<any>(this.base_path+"marketplace/is-product-same-store",{"store_id": JSON.parse(localStorage.getItem('store_id'))}, httpOptions)
        .pipe(
          retry(2),
          catchError(this.handleError)
        )
    }

    discardAllCartItems(){
      let httpOptions:any = this.getHeaderDetails()
      return this.http
        .delete<any>(this.base_path+"marketplace/clear-order", httpOptions)
        .pipe(
          retry(2),
          catchError(this.handleError)
        )
    }

    getAllStoresNearbyWthPagnation(latitude: any,longitude: any){
      let httpOptions:any = this.getHeaderDetails()
      return this.http
        .get<any>(this.base_path+"marketplace/get-all-stores?latitude="+latitude+"&longitude="+longitude, httpOptions)
        .pipe(
          retry(2),
          catchError(this.handleError)
        )
    }

    getSingleStoreDetails(storeid: any){
      let httpOptions:any = this.getHeaderDetails()
      return this.http
        .get<any>(this.base_path+"marketplace/storeinfo?latitude="+localStorage.getItem('latitude')+"&longitude="+localStorage.getItem('longitude')+"&store_id="+storeid, httpOptions)
        .pipe(
          retry(2),
          catchError(this.handleError)
        )
    }

    getStoreDetailsWithId(storeid: any){
      let httpOptions:any = this.getHeaderDetails()
      return this.http
        .get<any>(this.base_path+"marketplace/web-storeinfo?"+"store_id="+storeid, httpOptions)
        .pipe(
          retry(2),
          catchError(this.handleError)
        )
    }

    getPaymentKeys(store_id: AnalyserOptions){
      let httpOptions:any = this.getHeaderDetails()
      return this.http
        .post<any>(this.base_path+"marketplace/store-payment-keys",{"store_id": store_id}, httpOptions)
        .pipe(
          retry(2),
          catchError(this.handleError)
        )
    }
}
