import { Component } from '@angular/core';

import { Platform, AlertController  } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { FCM } from '@ionic-native/fcm/ngx'
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Device } from '@ionic-native/device/ngx';
import { menuController } from "@ionic/core";
import { File } from '@ionic-native/file/ngx';
import { ApiService } from './services/api/api.service';
import { async } from '@angular/core/testing';
import { environment } from '../environments/environment';
import { UpdateService } from './services/update/update.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  appName:any = environment.appName
  contactMail:any = environment.mailId
  selectedIndex:any
  currentPageTitle = 'Home';
  filelocation:any = ''
  defaultPic = "../../assets/icon/favicon.png"
  appPages = [
    {
      title: 'Home',
      url: '/market',
      icon: 'home'
    },
    {
      title: 'My Cart',
      url: '/cart',
      icon: 'cart'
    },
    // {
    //   title: 'Choose Language',
    //   url: '/choose-lang',
    //   icon: 'cart'
    // },
    // {
    //   title: 'Bulk Order',
    //   url: '/bulk-order',
    //   icon: 'briefcase'
    // },
    {
      title: 'My Orders',
      url: '/my-orders',
      icon: 'albums'
    },
    {
      title: 'My Addresses',
      url: '/my-addresses',
      icon: 'person-add'
    },
    {
      title: 'About',
      url: '/about',
      icon: 'information-circle'
    }
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private fcm: FCM,
    private router: Router,
    private _location: Location,
    public alertController: AlertController,
    private device: Device,
    private file: File,
    public api: ApiService,
    private updateService: UpdateService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      this.statusBar.styleLightContent();
      this.splashScreen.hide();

      await this.updateService.checkForUpdates()

      await localStorage.setItem("uuid",this.device.uuid)
      
      if (this.platform.is('hybrid')) {
        if(this.platform.is('android')){
          this.fcm.getToken().then(token => {
            // send this token to the server
            let fcmToken:any = token
            console.log(fcmToken)
            localStorage.setItem("fcm",fcmToken)
          })
        }
        else if(this.platform.is('ios')){
          this.fcm.hasPermission().then((hasPermission)=>{
            if(hasPermission){
              this.fcm.getToken().then(token => {
                // send this token to the server
                let fcmToken:any = token
                console.log(fcmToken)
                localStorage.setItem("fcm",fcmToken)
              })
            }
            else{
              this.fcm.requestPushPermissionIOS().then(() => {
                this.fcm.getToken().then(token => {
                  // send this token to the server
                  let fcmToken:any = token
                  console.log(fcmToken)
                  localStorage.setItem("fcm",fcmToken)
                })
              });
            }
          })
        }
      }

      if (this.platform.is('hybrid')) {
        this.fcm.onNotification().subscribe(data => {
          console.log(data);
          if (data.wasTapped) {
            console.log('Received in background', data);
            this.router.navigate(['home', {'from': data.fcm_from}]);
          } else {
            console.log('Received in foreground', data);
            this.router.navigate(['home', {'from': data.fcm_from}]);
          }
        });
      }


    });

    this.platform.backButton.subscribeWithPriority(10, () => {
      console.log('Back press handler!');
      if (this._location.isCurrentPathEqualTo('/market')) {

        // Show Exit Alert!
        console.log('Show Exit Alert!');
        this.showExitConfirm();
        // processNextHandler();
      }
      else if(this._location.isCurrentPathEqualTo('/home/'+localStorage.getItem('store_id'))) {
        this.router.navigate(['/market']);
      }
      else {

        // Navigate to back page
        console.log('Navigate to back page');
        this._location.back();

      }

    });

    this.platform.backButton.subscribeWithPriority(5, () => {
      console.log('Handler called to force close!');
      this.alertController.getTop().then(r => {
        if (r) {
          navigator['app'].exitApp();
        }
      }).catch(e => {
        console.log(e);
      })
    });

  }

  showExitConfirm() {
    this.alertController.create({
      header: 'App termination',
      message: 'Do you want to close the app?',
      backdropDismiss: false,
      buttons: [{
        text: 'Stay',
        role: 'cancel',
        handler: () => {
          console.log('Application exit prevented!');
        }
      }, {
        text: 'Exit',
        handler: () => {
          navigator['app'].exitApp();
        }
      }]
    })
      .then(alert => {
        alert.present();
      });
  }



  // backbuttonSubscribeMethod() {
  //   let a = 0;
  //   this.platform.backButton.subscribe(() => {
  //       a++;
  //       if (a == 2) { // logic for double tap
  //         navigator['app'].exitApp();
  //       }
  //   });
  // }

  showPrivacyPolicy(){
    setTimeout(() => menuController.toggle(), 250);
    this.router.navigate(['/privacy-policy']);
  }

  showTerms(){
    setTimeout(() => menuController.toggle(), 250);
    this.router.navigate(['/terms-conditions']);
  }

  goToHomePage(){
    this.router.navigate(['home']);
  }

  goTo(value:any){
    if(value == '/restaurants' || value == 'about'){
      this.router.navigate([value]);
    }
    else{
      if(localStorage.getItem('token') != undefined && localStorage.getItem('token') != null && localStorage.getItem('token') != '' && localStorage.getItem('token') != 'null'){
        this.router.navigate([value]);
      }
      else{
        localStorage.setItem('previouspage',"home")
        this.router.navigate(['/login']);
      }
    }
  }

  ngOnDestroy() {
    this.platform.backButton.unsubscribe();
  }

  logout(){
    localStorage.removeItem('token');
    setTimeout(() => menuController.toggle(), 250);
    window.location.reload()
  }

  showlogout() {
    if(localStorage.getItem('token') == undefined || localStorage.getItem('token') == 'null' || localStorage.getItem('token') == null || localStorage.getItem('token') == ''){
      return false
    }
    else{
      return true
    }
  }

  logoutConfirm() {
    this.alertController.create({
      header: 'Logout',
      message: 'Are you sure you want to logout of this app?',
      backdropDismiss: false,
      buttons: [{
        text: 'No',
        role: 'cancel',
        handler: () => {
          setTimeout(() => menuController.toggle(), 250);
          console.log('Application exit prevented!');
        }
      }, {
        text: 'Yes',
        handler: () => {
          this.logout()
        }
      }]
    })
      .then(alert => {
        alert.present();
      });
  }
}
