// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  countryCode: "91",
  baseURL : 'https://shops.onslr.com/api/', //QA
  countryName: '',
  stateName: '',
  contactNumber: 8072449191,
  appName: 'Onslr',
  mailId: 'beonmobil@gmail.com',
  bundleId: 'com.beonmobile.onslr',
  secretKey: '62656f6e6d6f62696c65323032326f6e736c7262656f6e6d6f62696c65323032'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
